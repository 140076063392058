const getCurrentUsersStatus = {
    url: `api/User/CurrentUsersStatus`,
    methodType: "GET",
    version: "1.0",

}

const userLogIn = {
    url: `api/User/UserLogIn`,
    methodType: "POST",
    version: "1.0",
}

const userLogOut = {
    url: `api/User/UserLogOut`,
    methodType: "POST",
    version: "1.0",
}

export const apis = {
    getCurrentUsersStatus,
    userLogIn,
    userLogOut
  }