import * as React from 'react';
import './App.css';
import classNames from 'classnames';
import { Modal } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import { message } from 'antd';
import { apis } from './api/apiService';
import useApi from '@bit/hlouzek.rhplus.api';


const stateInit = {
  visible: false,
  disabled: true,
  bounds: { left: 0, top: 0, bottom: 0, right: 0 },
}


export default function App() {
  const [state, setState] = React.useState(stateInit);
  const { callApi, errorCatch } = useApi(process.env.REACT_APP_PROXY);
  const { getCurrentUsersStatus, userLogIn, userLogOut } = apis;
  const [users, setUsers] = React.useState([]);
  const [currentUser, setCurrentUser] = React.useState();
  const [status, setStatus] = React.useState();


  React.useEffect(() => {
    loadUsers();
  }, []);



  const loadUsers = async () => {
    const response = await callApi({ ...getCurrentUsersStatus })
    setUsers(response.data.currentUsers)
    console.log('users', users);
  }

  const logIn = async (key) => {
    const logInResponse = await callApi({ ...userLogIn, paramObject: { key: key }});
    setCurrentUser(logInResponse.data);
    updateUsers(logInResponse.data);
    setState({
      visible: false
    });
    success();
  }

  const updateUsers = (currentUser) => {
    const newUsers = users.map(u => {
      if (u.user.key === currentUser.user.key) 
        return currentUser;
      else
        return u;
    });
    setUsers(newUsers);
  }

  const logOut = async (key) => {
    const logOutResponse = await callApi({ ...userLogOut, paramObject : {key: key}})
    setCurrentUser(logOutResponse.data);
    updateUsers(logOutResponse.data);
    setState({
      visible: false
    });
    warning();
  }

  const handleCancel = () => {
    setState({
      visible: false
    });
  }


  const success = () => {
    message.success('Přihlášení probělho v pořádku');
  };

  const warning = () => {
    message.warning('Byl jste úspěšně odhlášen');
  };

  const showUsers = () => {
    return users.map(item => {
      const surname = item.user.lastName != null ? item.user.lastName : " "
      return (
        <div className={classNames("tile", showColor(item))} onClick={e => onTileClick(item)}>
          <h3>{`${item.user.firstName} ${surname}`}</h3>
        </div>
      )
    }
    );
  }

  const showColor = user => user.move.direction ? "loged-in" : "loged-out";

  const onTileClick = (item) => {
    setCurrentUser(item);
    setState({
      visible: true
    });

  }


  const modalBody = () => {
    if (!currentUser)
      return;
    const status = currentUser.move.direction;
    if (status === false ){
      return (
        <div>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <h2>
              {currentUser.user.firstName}, chcete se opravdu přihlásit?
        </h2>
            <br />
            <h2>{moment().format("DD.MM. HH:mm:ss")}</h2>
          </div>
          <div className="buttons">
            <div className="log-in-button" onClick={() => logIn(currentUser.user.key)}>
              <h3>Přihlásit</h3>
            </div>
          </div>
        </div>
      )
    }
    else if (status === true) {
      return (
        <div>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <h2>
            {currentUser.user.firstName}, chcete se opravdu odhlásit?
        </h2>
            <br />
            <h2>{moment().format("DD.MM. HH:mm:ss")}</h2>
          </div>
          <div className="buttons">
            <div className="log-out-button" onClick={() => logOut(currentUser.user.key)}>
              <h3>Odhlásit</h3>
            </div>
          </div>
        </div>
      )
    }
    
  }

  const { bounds, disabled, visible } = state;
  return (
    <div className="App">
      <Modal
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
            }}
            // onMouseOver={() => {
            //   if (disabled) {
            //     setState({
            //       disabled: false,
            //     });
            //   }
            // }}
            // onMouseOut={() => {
            //   setState({
            //     disabled: true,
            //   });
            // }}
            onFocus={() => { }}
            onBlur={() => { }}
          >
            Přihlášení
            </div>
        }
        visible={visible}
        footer={null}
        onCancel={handleCancel}
      >
        {modalBody()}
      </Modal>
      <div className="container">
        {showUsers()}
      </div>
    </div>
  );
}



